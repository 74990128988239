@import "theme-variables";

p-chips {
  .p-chips {
    box-shadow: $secondary-shadow;
    border: none;
    min-height: $input-height;

    &,
    .p-chips-multiple-container {
      width: 100%;
      border-radius: $input-border-radius;
    }

    .p-chips-multiple-container {
      padding: 0.5rem 1.5rem;

      &:has(:first-child(.p-chips-token)) {
        padding: unset;
      }

      &:not(.p-disabled).p-focus {
        box-shadow: $focus-shadow;
      }

      .p-chips-token {
        display: inline-flex;
        font-size: 0.875rem;
        color: $primary;
        padding: 0.5rem;
        border-radius: 1rem;
        background-color: $primary-lighter;
        cursor: initial;
        margin: 0.25rem 0;
        word-break: break-word;
        width: fit-content;

        &:not(:last-child) {
          margin-right: 0.5rem;
        }
      }

      .p-chips-input-token {
        input {
          font-family: $root-font-family;

          &::placeholder {
            font-weight: normal;
            color: $placeholder-color;
          }
        }
      }

      .p-chips-token.danger {
        background-color: $danger-light;
        color: $danger;
      }
    }
  }
}
